<template>
  <v-container class="fill-height" fluid data-cy="onboarding-page">
    <v-row align="center" justify="center">
      <v-col cols="12" sm="8" md="4">
        <Onboarding
          :email="email"
          :organisationId="queryOrganisationId"
          :lang="lang"
        />
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import Onboarding from "@/modules/auth/components/Onboarding.vue";
import ForcedOrganisationIdPages from "@/modules/auth/mixins/forcedOrganisationIdPages.mixin.js";

export default {
  name: "OnboardingPage",
  mixins: [ForcedOrganisationIdPages],
  components: {
    Onboarding,
  },
  computed: {
    email() {
      let email = "";
      if (this.$route.query && this.$route.query.email) {
        email = this.$route.query.email.toString();
      }
      return email;
    },
    lang() {
      let lang = "";
      if (this.$route.query && this.$route.query.lang) {
        lang = this.$route.query.lang.toString();
      }
      return lang;
    },
  },
};
</script>
